<div
  class="fixed bottom-0 h-fit max-h-full w-full overflow-auto pt-[68px] text-gray-500 transition-opacity"
  [ngClass]="isHandleHidden() ? 'pointer-events-none opacity-0' : 'opacity-100'"
>
  <div class="flex flex-col border-t border-gray-200 bg-gray-50">
    <div class="flex justify-end px-4 py-2 md:py-4">
      <app-button
        (click)="toggleFooter()"
        color="gray"
        outline
        iconRight="chevron-{{ isFooterHidden() ? 'up' : 'down' }}"
        size="small"
      >
        Footer {{ isFooterHidden() ? "zeigen" : "ausblenden" }}
      </app-button>
    </div>
    <div class="overflow-auto px-20 py-14" [class.hidden]="isFooterHidden()">
      <div class="flex flex-col items-center justify-between gap-4 lg:flex-row">
        <img loading="lazy" src="assets/images/logo.svg" alt="Events 069" />
        <div class="flex flex-col gap-8 text-center lg:flex-row">
          @for (link of links; track link.title) {
            @if (link.external) {
              <a class="cursor-pointer" target="_blank" [href]="link.path">
                {{ link.title }}
              </a>
            } @else {
              <span class="cursor-pointer" [routerLink]="link.path">
                {{ link.title }}
              </span>
            }
          }
        </div>
      </div>
      <div class="my-8 h-1px w-full bg-gray-200"></div>
      <div
        class="flex flex-col justify-between gap-6 text-center text-xs lg:flex-row"
      >
        <span>&copy; 2024 Events 069</span>
        <div class="flex flex-col gap-2 lg:flex-row lg:gap-6">
          <span
            class="cursor-pointer"
            *ngFor="let link of bottomLinks"
            [routerLink]="link.path"
          >
            {{ link.title }}
          </span>
          <span class="cursor-pointer" (click)="openCookieSettings()">
            Cookie Consent
          </span>
        </div>
        <!--      <div class="flex flex-col items-center gap-2 lg:flex-row">-->
        <!--        <svg-icon src="assets/icons/instagram.svg"></svg-icon>-->
        <!--      </div>-->
      </div>
    </div>
  </div>
</div>
