import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { ButtonComponent } from '@shared/_components/button/button.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass, NgForOf, NgOptimizedImage, NgStyle } from '@angular/common';
import { DropdownComponent } from '@shared/_components/_input-components/dropdown/dropdown.component';
import { DropdownItemComponent } from '@shared/_components/_input-components/dropdown/dropdown-item/dropdown-item.component';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    ButtonComponent,
    RouterLinkActive,
    RouterLink,
    NgForOf,
    DropdownComponent,
    DropdownItemComponent,
    SvgIconComponent,
    NgClass,
    NgStyle,
    NgOptimizedImage,
  ],
  templateUrl: './navbar.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  isMenuOpen = signal(false);

  links: {
    title: string;
    path: string;
    external?: boolean;
  }[] = [
    {
      title: 'Veranstaltungen',
      path: '/',
    },
    {
      title: 'Über uns',
      path: '/über-uns',
    },
    {
      title: 'Kontakt',
      path: 'mailto:info@events-069.de',
      external: true,
    },
  ];

  toggleMenu() {
    this.isMenuOpen.update((isOpen) => !isOpen);
  }

  closeMenu() {
    this.isMenuOpen.set(false);
  }
}
