import {
  CanActivateFn,
  createUrlTreeFromSnapshot,
  Routes,
} from '@angular/router';
import { combineLatest, map } from 'rxjs';
import { inject } from '@angular/core';
import { PaymentService } from '@app/pages/create-event/payment.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { EventStorageService } from '@app/pages/create-event/event-storage.service';

/**
 * If there is no event, redirect to create event page
 * @param route
 * @param state
 */
const canProceedCheckout: CanActivateFn = (route, state) => {
  const createEventService = inject(EventStorageService);

  return combineLatest([
    toObservable(createEventService.event$),
    createEventService.images$,
  ]).pipe(
    map(([event, images]) =>
      !event || !images || images.length === 0
        ? createUrlTreeFromSnapshot(route, ['../'])
        : true,
    ),
  );
};

/**
 * If checkout page is left, unmount the embedded stripe checkout
 * @param route
 * @param state
 */
const leaveCheckout: CanActivateFn = (route, state) => {
  const paymentService = inject(PaymentService);

  paymentService.destroyEmbeddedCheckout();

  return true;
};

export const routes: Routes = [
  {
    path: 'event-anlegen',
    loadComponent: () =>
      import('./pages/create-event/create-event.component').then(
        (m) => m.CreateEventComponent,
      ),
    data: {
      hideFooter: true,
    },
  },
  {
    path: 'event-anlegen/checkout',
    loadComponent: () =>
      import('./pages/create-event/payment/payment.component').then(
        (m) => m.PaymentComponent,
      ),
    canDeactivate: [leaveCheckout],
    canActivate: [canProceedCheckout],
  },
  {
    path: 'events/:slug',
    loadComponent: () =>
      import('./pages/event-detail/event-detail.component').then(
        (m) => m.EventDetailComponent,
      ),
  },
  {
    path: 'über-uns',
    loadComponent: () =>
      import('./pages/about-us/about-us.component').then(
        (m) => m.AboutUsComponent,
      ),
  },
  {
    path: 'datenschutz',
    loadComponent: () =>
      import('./pages/privacy/privacy.component').then(
        (m) => m.PrivacyComponent,
      ),
  },
  {
    path: 'impressum',
    loadComponent: () =>
      import('./pages/imprint/imprint.component').then(
        (m) => m.ImprintComponent,
      ),
  },
  {
    path: 'agb',
    loadComponent: () =>
      import('./pages/terms-of-conditions/terms-of-conditions.component').then(
        (m) => m.TermsOfConditionsComponent,
      ),
  },
  {
    path: '',
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
    pathMatch: 'full',
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/',
  },
];
