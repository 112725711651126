import {
  APP_INITIALIZER,
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
} from '@angular/core';
import { provideRouter, Router, withRouterConfig } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import {
  IMAGE_LOADER,
  ImageLoaderConfig,
  registerLocaleData,
} from '@angular/common';
import de from '@angular/common/locales/de';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { provideNgxStripe } from 'ngx-stripe';
import { environment } from '@env';
import { provideApollo } from '@app/graphql.provider';
import * as Sentry from '@sentry/angular';

registerLocaleData(de);

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
    ),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    provideAngularSvgIcon(),
    provideApollo(),
    provideNgxStripe(environment.stripePublishableKey, {
      locale: 'de',
    }),
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR',
    },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        if (
          config.isPlaceholder &&
          config.loaderParams &&
          'previewUrl' in config.loaderParams
        ) {
          return config.loaderParams['previewUrl'];
        }

        return config.src;
      },
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
